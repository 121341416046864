import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RegistrationLayoutNew from 'layouts/RegistrationLayoutNew';
import RegistrationNew from 'components/RegistrationNew';
import { CircularProgress } from '@material-ui/core';

const RegistrationPageNew = ({
  locationRoute,
  isLocationValid,
  loadClubs,
  loadSortedClubs,
  generateHashSaga,
  accessToken,
  //Location routes logic
  membershipPlanInfo,
  getMembershipPlanInfo,
  insertChatbotOlsRecord,
  isChatbotRecordInserted,
  updateChatbotRecords,
  isChatbotRecordsUpdated,
  updateOlsTracking,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [tab, setTab] = useState(0);
  const [section, setSection] = useState(isLocationValid === true ? 1 : 0);
  const [isLoading, setisLoading] = useState(true);
  const [isStartingPage, setIsStartingPage] = useState(false);

  useEffect(() => {
    if (isLocationValid && Object.keys(accessToken).length !== 0 && !isStartingPage) {
      getMembershipPlanInfo(locationRoute);
    } else if (!isLocationValid) {
      setisLoading(false);
    }
  }, [isLocationValid, accessToken]);

  useEffect(() => {
    if (Object.keys(membershipPlanInfo).length !== 0) {
      setisLoading(false);
    }
  }, [membershipPlanInfo]);

  function handleFirstPageFlow(value) {
    setIsStartingPage(value);
  }

  const titles = [
    'Choose a location',
    'Choose a membership',
    'Provide your email address',
    'Provide your personal Info',
    'Take your photo',
    'Add your payment method',
    'Terms of Service',
    'Confirm and pay',
    'Edit your payment method',
    'Congratulation!',
  ];

  useEffect(() => {
    const getHash = async () => {
      await generateHashSaga();
    };

    getHash();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress style={{ color: '#fe5000' }} />
        </div>
      ) : (
        <RegistrationLayoutNew
          section={section}
          setSection={setSection}
          setTab={setTab}
          title={title}
          setTitle={setTitle}
          tab={tab}
          titles={titles}
          isLocationValid={isLocationValid}
          {...props}
        >
          <RegistrationNew
            section={section}
            setSection={setSection}
            tab={tab}
            {...props}
            setTab={setTab}
            title={title}
            setTitle={setTitle}
            titles={titles}
            loadSortedClubs={loadSortedClubs}
            locationRoute={locationRoute}
            isLocationValid={isLocationValid}
            accessToken={accessToken}
            insertChatbotOlsRecord={insertChatbotOlsRecord}
            isChatbotRecordInserted={isChatbotRecordInserted}
            updateChatbotRecords={updateChatbotRecords}
            isChatbotRecordsUpdated={isChatbotRecordsUpdated}
            membershipPlanInfo={membershipPlanInfo}
            handleFirstPageFlow={handleFirstPageFlow}
            updateOlsTracking={updateOlsTracking}
          />
        </RegistrationLayoutNew>
      )}
    </>
  );
};

RegistrationPageNew.propTypes = {
  clubs: PropTypes.array.isRequired,
  loadClubs: PropTypes.func.isRequired,
};

export default RegistrationPageNew;
