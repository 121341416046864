import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { buddyReferralCloseIcon } from 'assets/icons';
import styles from './style.module.css';
import { gift } from 'assets/gifs';
import MobileContext from 'context/MobileContext';

const ReferralPopup = ({ open, onClose, referralPerson }) => {
  const isMobile = useContext(MobileContext);

  return (
    <div>
      <Dialog open={open} onClose={onClose} classes={{ paper: styles.dialogPaper }}>
        <div className={styles.wrapper}>
          <img src={gift} alt="gif" className={styles.gift} />
          <div className={styles.closeIcon}>
            <img
              onClick={() => onClose()}
              src={buddyReferralCloseIcon}
              alt="close icon"
            />
          </div>
          <div className={styles.content}>
            <div
              dangerouslySetInnerHTML={{
                __html: referralPerson?.olsEeferralPopupHeader,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: referralPerson?.olsReferralPopupText,
              }}
              style={isMobile ? { marginTop: '20px' } : {}}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

ReferralPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  referralPerson: PropTypes.object.isRequired,
};

export default ReferralPopup;
