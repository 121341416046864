/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import SelectClubSection from './SelectClubSection';
import SelectPlanSection from './SelectPlanSection';
import PersonalInfo from './PersonalInfo';
import TermsConditions from './TermsConditions';
import TakePhotoSection from './TakePhotoSection';
// import CongratulationsSection from './CongratulationsSection';
import PaymentConfirmationSection from './PaymentConfirmationSection';
import SubmitScreen from './SubmitScreen';
import styles from './style.module.css';
import ReferralPopup from './ReferralPopup';
import EmailAddressInfo from './ProvideEmailAddress';
import { geoLocation, geoSearch } from 'assets/icons';
import GeoLocationInput from 'components/shared/GeoLocationInput';
import MobileContext from 'context/MobileContext';
import SmallMobileContext from 'context/SmallMobileContext';
import LargeMobileContext from 'context/LargeMobile';
import AddPayment from './AddPayment';
import EditPayment from './EditPayment';

const RegistrationNew = ({
  clubs,
  loadSortedClubs,
  newOlsPlans,
  getNewOlsPlans,
  planInfo,
  findUserByEmail,
  userExistenceData,
  addUserPersonalInfo,
  userPersonalInfo,
  tab,
  url,
  title,
  setTab,
  setTitle,
  titles,
  card,
  referralPerson,
  isRequesting,
  loadBillingUrl,
  addCard,
  createNewUser,
  checkReferralCode,
  section,
  setSection,
  uploadedPhoto,
  uploadPhoto,
  newUserInfo,
  addExistingUserNewCard,
  existingUserNewCard,
  updateExistingUser,
  editedCardInfo,
  editExistingUserCard,
  editNewCard,
  newOlsUrl,
  loadNewOlsBillingUrl,
  getPaymentDetails,
  paymentDetails,
  locationRoute,
  isLocationValid,
  membershipPlanInfo,
  updatedUserDetails,
  resetArrayState,
  resetObjectState,
  isRequestingEmail,
  isRequestingNewUser,
  isRequestingUploadPhoto,
  isRequestingUpdateExistingUser,
  setError,
  accessToken,
  isChatbotRecordInserted,
  insertChatbotOlsRecord,
  updateChatbotRecords,
  isChatbotRecordsUpdated,
  handleFirstPageFlow,
  updateUtmInfo,
  updateOlsTracking,
  olsTrackingData,
  trackingSessionID,
  resetTrackingData,
  getSubmitFailureData,
  submitFailureData,
  loadingRetryUser,
  isCreateUserRetryFailed,
  retryUserData,
}) => {
  const isMobile = useContext(MobileContext);
  const isSmallMobile = useContext(SmallMobileContext);
  const isLargeMobile = useContext(LargeMobileContext);

  //Clubs
  const [selectedClub, setSelectedClub] = useState(null);
  //Plans
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanName, setSelectedPlanName] = useState(null);
  //emailAdress
  const [emailAddress, setemailAddress] = useState('');
  const [userExist, setuserExist] = useState(false);
  const [state, setState] = useState('');

  const [location, setlocation] = useState('');
  const [photo, takePhoto] = useState(userPersonalInfo?.photo || '');
  const [isOpenReferralPopup, setOpenReferralPopup] = useState(false);
  const [termsAccept, setTermsAccept] = useState(userPersonalInfo?.termsAccept || false);
  const [editDetailsOnNext, seteditDetailsOnNext] = useState(0);
  const chatbotSessionId = localStorage.getItem('sessionId');
  const currentPath = window.location.pathname.substring(1).split('/')[0];
  const [isStep2Done, setIsStep2Done] = useState(false);
  const [isStep3Done, setIsStep3Done] = useState(false);
  const [utmParams, setUtmParams] = useState({
    utmCampaign: '',
    utmMedium: '',
    utmContent: '',
    utmSource: '',
  });

  useEffect(() => {
    localStorage.removeItem('email');
    const params = new URLSearchParams(history?.location?.search);

    setUtmParams({
      utmCampaign: params.get('utm_campaign') || '',
      utmMedium: params.get('utm_medium') || '',
      utmContent: params.get('utm_content') || '',
      utmSource: params.get('utm_source') || '',
    });
  }, []);

  const emailChanged = () => {
    resetTrackingData();
    setIsStep2Done(false);
    setIsStep3Done(false);
  };

  const data = {
    session: '',
    email: emailAddress,
    currentPage: '',
    data: '',
    isFormerMember: userExistenceData?.data?.status === 200 ? true : false,
    isReferral: Object.keys(referralPerson).length !== 0,
    referrerName: Object.keys(referralPerson).length !== 0 ? referralPerson.name : null,
    referrerCaid: Object.keys(referralPerson).length !== 0 ? referralPerson?.caID : null,
  };

  useEffect(() => {
    if (Object.keys(userExistenceData).length !== 0) {
      if (!trackingSessionID) {
        data.currentPage = 'Choose a location';
        data.data = JSON.stringify({ location: currentPath });
        updateOlsTracking(data);
      }
      if (isStep2Done && !isStep3Done) {
        setIsStep3Done(true);
        data.session = trackingSessionID;
        data.currentPage = 'Provide your email address';
        data.data = JSON.stringify({ email: emailAddress });
        updateOlsTracking(data);
      }
      if (
        Object.keys(olsTrackingData).length !== 0 &&
        trackingSessionID &&
        !isStep2Done
      ) {
        data.session = trackingSessionID;
        data.currentPage = 'Choose a membership';
        data.data = JSON.stringify({ membership: selectedPlanName });
        updateOlsTracking(data);
        setIsStep2Done(true);
      }
    }
  }, [userExistenceData, olsTrackingData]);

  const handleConvertPhotoIntoBinary = photo => {
    const base64Data = photo.split(',')[1].replace(/\s/g, '');
    const standardBase64Data = base64Data.replace(/-/g, '+').replace(/_/g, '/');
    const binaryData = atob(standardBase64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
    return blob;
  };

  useEffect(() => {
    if (
      locationRoute !== undefined &&
      Object.keys(membershipPlanInfo).length !== 0 &&
      isLocationValid === true
    ) {
      setTab(1);
      setTitle(titles[1]);
      setSection(1);
    }
  }, [locationRoute && membershipPlanInfo && isLocationValid]);

  // set boolean for user exist or not
  useEffect(() => {
    const status = userExistenceData?.data?.status;
    if (status === 404) {
      setuserExist(false);
    } else if (status === 200) {
      setuserExist(true);
    }
  }, [userExistenceData]);

  // get newOlsPlans
  useEffect(() => {
    if (selectedClub) getNewOlsPlans(selectedClub);
  }, [selectedClub, getNewOlsPlans]);

  useEffect(() => {
    if (photo) addUserPersonalInfo({ ...userPersonalInfo, photo });
  }, [photo, addUserPersonalInfo]);

  useEffect(() => {
    if (termsAccept) addUserPersonalInfo({ ...userPersonalInfo, termsAccept });
  }, [termsAccept, addUserPersonalInfo]);

  useEffect(() => {
    if (history?.location?.search) checkReferralCode(history?.location?.search);
  }, []);

  useEffect(() => {
    if (Object.keys(membershipPlanInfo).length === 0) {
      setTitle(titles[0]);
    } else {
      setSelectedClub(membershipPlanInfo?.data?.clubId);
    }
  }, [membershipPlanInfo]);

  useEffect(() => {
    const { name, plan = null, clubSfId = null } = referralPerson;
    if (clubSfId) setSelectedClub(clubSfId);
    if (plan?.id) {
      setSelectedPlan(+plan?.id);
      onNext(2);
      setSection(2);
    }
    if (name && !plan) setOpenReferralPopup(true);
  }, [referralPerson]);

  useEffect(() => {
    if (tab === 0 && section === 0) {
      setSelectedClub(null);
    }
  }, [tab]);

  useEffect(() => {
    if (Object.keys(accessToken).length !== 0 && chatbotSessionId) {
      insertChatbotOlsRecord(chatbotSessionId);
    }
  }, [accessToken, chatbotSessionId]);

  useEffect(() => {
    const updateChatbotData = async () => {
      if (isChatbotRecordInserted) {
        const data = {
          sessionId: chatbotSessionId,
          currentPage: title,
          data: {
            location:
              selectedClub && title !== 'Choose a location'
                ? getSelectedLocationByName(selectedClub)
                : null,
          },
        };
        try {
          await updateChatbotRecords(data);
        } catch (error) {
          console.error('Error updating chatbot records:', error);
        }
      }
    };

    updateChatbotData();
  }, [isChatbotRecordInserted, title]);

  useEffect(() => {
    if (isChatbotRecordsUpdated) {
      const chatbotIframe = document.getElementById('chatbotIframe');
      chatbotIframe.contentWindow.postMessage(
        { type: 'records', sessionId: chatbotSessionId, updatedData: true },
        '*'
      );
    }
  }, [isChatbotRecordsUpdated]);

  function getSelectedLocationByName(selectedNumber) {
    for (let i = 0; i < clubs?.length; i++) {
      if (clubs[i]?.clubautomationclubidC === selectedNumber.toString()) {
        return clubs[i]?.displayName;
      }
    }
  }

  const onNext = (newTitle, subTitle) => {
    if (newTitle) setTitle(titles[newTitle]);
    if (newTitle === 1) {
      setTab(1);
    } else if (newTitle === 2) {
      setTab(2);
      if (subTitle === 2) {
        setTitle(titles[subTitle]);
      } else if (subTitle === 3) {
        setTitle(titles[subTitle]);
      } else {
        setTitle(null);
      }
    } else if (newTitle === 4) {
      setTab(3);
    } else if (newTitle === 5) {
      setTab(4);
    } else if (newTitle === 6) {
      setTab(5);
      if (subTitle === 6) {
        setTitle(titles[subTitle]);
      } else if (subTitle === 7) {
        setTitle(titles[subTitle]);
      } else {
        setTitle(null);
      }
    } else if (newTitle === 9) {
      setTitle(titles[newTitle]);
    }
    setSection(section + 1);
  };

  const onNextEditPayment = (newTitle, subTitle, sec) => {
    if (sec === 6) {
      if (newTitle === 5) {
        setTab(newTitle);
        if (subTitle === 6) {
          setTitle(titles[subTitle]);
          setSection(6);
        }
      }
    }
    if (sec === 7) {
      if (newTitle === 6) {
        setTab(5);
        if (subTitle === 7) {
          setTitle(titles[subTitle]);
          setSection(7);
        }
      }
      if (newTitle === 5) {
        setTab(newTitle);
        if (subTitle === 7) {
          setTitle(titles[subTitle]);
          setSection(sec);
        }
      }
    }
    if (sec === 9) {
      if (newTitle === 4) {
        setTab(4);
        if (subTitle === 8) {
          setTitle(titles[subTitle]);
          setSection(sec);
        }
      }
    }
  };

  const handleEditDetailsOnNext = (nextTab, nextTitle, sec) => {
    if (nextTab === 4) {
      setTab(nextTab);
      if (nextTitle === 8) {
        setTitle(titles[nextTitle]);
        if (sec === 9) {
          setSection(sec);
        }
      }
    }
    if (nextTab === 5) {
      setTab(nextTab);
      if (nextTitle === 7) {
        setTitle(titles[nextTitle]);
        if (sec === 7) {
          setSection(sec);
        }
      }
      if (nextTitle === 6) {
        setTitle(titles[nextTitle]);
        if (sec === 6) {
          setSection(sec);
        }
      }
    }
  };

  const onBackTerms = (nextTab, nextTitle, sec) => {
    if (nextTab === 4) {
      setTab(nextTab);
      if (nextTitle === 8) {
        setTitle(titles[nextTitle]);
        if (sec === 9) {
          setSection(sec);
        }
      }
    }
  };

  const onBack = (newTitle, subTitle) => {
    // console.warn(newTitle, subTitle);
    if (newTitle) setTitle(titles[newTitle]);
    if (newTitle === 0) {
      setTab(0);
      setSelectedClub(null);
      setTitle(titles[newTitle]);
    }
    if (newTitle === 1) {
      setTab(1);
      setTitle(titles[newTitle]);
    }
    if (newTitle === 2) {
      setTab(2);
      if (subTitle === 2) {
        setTitle(titles[subTitle]);
      } else if (subTitle === 3) {
        setTitle(titles[subTitle]);
      }
    }
    if (newTitle === 4) {
      setTab(5);
      setTitle(titles[subTitle]);
    }
    // if (newTitle === 3) setTab(3);
    // else setTitle(null);

    setSection(section - 1);
  };

  const onEditPaymentBack = (tab, title, section) => {
    if (tab === 3) {
      setTab(3);
      if (title === 4) {
        setTitle(titles[title]);
        setSection(section);
      }
    }
  };

  const handleGetZip = e => {
    if (/^\d{5,}$/.test(e)) {
      let data = {
        zip: location !== '' ? location : null,
      };
      loadSortedClubs(data);
    }
  };

  const openGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        let data = {
          zip: null,
        };
        setlocation('');
        loadSortedClubs(data);
      },
      error => {
        let data = {
          zip: null,
        };
        setlocation('');
        loadSortedClubs(data);
        console.error('Error getting location:', error.message);
      }
    );
  };

  const components = [
    <SelectClubSection
      loadSortedClubs={loadSortedClubs}
      clubs={clubs}
      selectedClub={selectedClub}
      setSelectedClub={setSelectedClub}
      onNext={() => onNext(1)}
      isMobile={isMobile}
      isSmallMobile={isSmallMobile}
      isLargeMobile={isLargeMobile}
      handleFirstPageFlow={handleFirstPageFlow}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      userExist={userExist}
      referralPerson={referralPerson}
    />,

    <SelectPlanSection
      resetArrayState={resetArrayState}
      selectedClub={
        selectedClub &&
        clubs.find(({ clubautomationclubidC }) => selectedClub == clubautomationclubidC)
      }
      clubs={clubs}
      newOlsPlans={newOlsPlans}
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      onNext={() => onNext(2, 2)}
      onBack={() => onBack(0)}
      isMobile={isMobile}
      isSmallMobile={isSmallMobile}
      membershipPlanInfo={membershipPlanInfo}
      setSelectedPlanName={setSelectedPlanName}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      userExist={userExist}
      referralPerson={referralPerson}
    />,
    <EmailAddressInfo
      emailAddress={emailAddress}
      setemailAddress={setemailAddress}
      findUserByEmail={findUserByEmail}
      onNext={() => onNext(2, 3)}
      onBack={() => onBack(1)}
      isMobile={isMobile}
      isSmallMobile={isSmallMobile}
      resetObjectState={resetObjectState}
      userExistenceData={userExistenceData?.data}
      isRequestingEmail={isRequestingEmail}
      setError={setError}
      referralPerson={referralPerson}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      title={title}
      userExist={userExist}
      emailChanged={emailChanged}
    />,
    <PersonalInfo
      userPersonalInfo={userPersonalInfo}
      addUserPersonalInfo={addUserPersonalInfo}
      isMobile={isMobile}
      onNext={() => onNext(4)}
      onBack={() => onBack(2, 2)}
      handleEditDetailsOnNext={() => handleEditDetailsOnNext(4, 8, 9)}
      editDetailsOnNext={editDetailsOnNext}
      state={state}
      setState={setState}
      resetObjectState={resetObjectState}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      userExist={userExist}
      referralPerson={referralPerson}
    />,
    <TakePhotoSection
      handleConvertPhotoIntoBinary={handleConvertPhotoIntoBinary}
      userExist={userExist}
      uploadPhoto={uploadPhoto}
      userExistenceData={userExistenceData?.data?.data}
      isMobile={isMobile}
      photo={photo}
      takePhoto={takePhoto}
      onNext={() =>
        Object.keys(card).length === 0 ? onNext(5) : onNextEditPayment(4, 8, 9)
      }
      onBack={() => onBack(2, 3)}
      editDetailsOnNext={editDetailsOnNext}
      handleEditDetailsOnNext={() => handleEditDetailsOnNext(4, 8, 9)}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      referralPerson={referralPerson}
    />,
    <AddPayment
      addExistingUserNewCard={addExistingUserNewCard}
      userExist={userExist}
      isMobile={isMobile}
      userExistenceData={userExistenceData}
      url={url}
      loadBillingUrl={loadBillingUrl}
      loadNewOlsBillingUrl={loadNewOlsBillingUrl}
      newOlsUrl={newOlsUrl}
      addCard={addCard}
      onNext={() => {
        onNext(6, 6);
      }}
      onBack={() => onBack(4)}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      referralPerson={referralPerson}
    />,
    <TermsConditions
      userExist={userExist}
      newUserInfo={newUserInfo}
      selectedClub={
        selectedClub &&
        clubs.find(({ clubautomationclubidC }) => selectedClub === clubautomationclubidC)
      }
      selectedPlan={
        referralPerson?.plan || newOlsPlans.find(({ id }) => selectedPlan === id)
      }
      createNewUser={createNewUser}
      userExistenceData={userExistenceData}
      emailAddress={emailAddress}
      card={card}
      userPersonalInfo={userPersonalInfo}
      termsAccept={termsAccept}
      setTermsAccept={setTermsAccept}
      isMobile={isMobile}
      isSmallMobile={isSmallMobile}
      plan={referralPerson?.plan || newOlsPlans.find(p => p.id === selectedPlan)}
      onNext={() => onNext(6, 7)}
      onBack={() => onBack(6, 6)}
      onBackTerms={() => onBackTerms(4, 8, 9)}
      paymentDetails={paymentDetails}
      getPaymentDetails={getPaymentDetails}
      referralPerson={referralPerson}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      title={title}
    />,
    <SubmitScreen
      uploadedPhoto={uploadedPhoto}
      photo={photo}
      editedCardInfo={editedCardInfo}
      seteditDetailsOnNext={seteditDetailsOnNext}
      userExist={userExist}
      existingUserNewCard={existingUserNewCard}
      updateExistingUser={updateExistingUser}
      emailAddress={emailAddress}
      handleConvertPhotoIntoBinary={handleConvertPhotoIntoBinary}
      userPersonalInfo={userPersonalInfo}
      newUserInfo={newUserInfo}
      setTitle={setTitle}
      titles={titles}
      section={section}
      setSection={setSection}
      setTab={setTab}
      tab={tab}
      isMobile={isMobile}
      termsAccept={termsAccept}
      setTermsAccept={setTermsAccept}
      selectedClub={
        selectedClub &&
        clubs.find(({ clubautomationclubidC }) => selectedClub === clubautomationclubidC)
      }
      selectedPlan={
        referralPerson?.plan || newOlsPlans.find(({ id }) => selectedPlan === id)
      }
      card={card}
      planInfo={planInfo}
      createNewUser={createNewUser}
      uploadPhoto={uploadPhoto}
      userExistenceData={userExistenceData}
      onNext={() => onNext(9)}
      isRequesting={isRequesting}
      onBack={() => onBack(4, 6)}
      paymentDetails={paymentDetails}
      state={state}
      resetObjectState={resetObjectState}
      updatedUserDetails={updatedUserDetails}
      referralPerson={referralPerson}
      isRequestingNewUser={isRequestingNewUser}
      isRequestingUploadPhoto={isRequestingUploadPhoto}
      isRequestingUpdateExistingUser={isRequestingUpdateExistingUser}
      utmParams={utmParams}
      updateUtmInfo={updateUtmInfo}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      title={title}
      setError={setError}
      getSubmitFailureData={getSubmitFailureData}
      submitFailureData={submitFailureData}
      loadingRetryUser={loadingRetryUser}
      isCreateUserRetryFailed={isCreateUserRetryFailed}
      retryUserData={retryUserData}
    />,
    <PaymentConfirmationSection />,
    <EditPayment
      editNewCard={editNewCard}
      existingUserNewCard={existingUserNewCard}
      userPersonalInfo={userPersonalInfo}
      userExistenceData={userExistenceData}
      addExistingUserNewCard={addExistingUserNewCard}
      editExistingUserCard={editExistingUserCard}
      userExist={userExist}
      isMobile={isMobile}
      card={card}
      url={url}
      newOlsUrl={newOlsUrl}
      onNext={() =>
        editDetailsOnNext === 2 || editDetailsOnNext === 3 || editDetailsOnNext === 9
          ? handleEditDetailsOnNext(5, 7, 7)
          : handleEditDetailsOnNext(5, 6, 6)
      }
      onBack={() =>
        // editDetailsOnNext === 2 || editDetailsOnNext === 3 || editDetailsOnNext === 9
        //   ? handleEditDetailsOnNext(5, 7, 7)
        // :
        // handleEditDetailsOnNext(5, 6, 6)
        onEditPaymentBack(3, 4, 4)
      }
      loadBillingUrl={loadBillingUrl}
      loadNewOlsBillingUrl={loadNewOlsBillingUrl}
      addCard={addCard}
      updateOlsTracking={updateOlsTracking}
      trackingId={trackingSessionID}
      emailAddress={emailAddress}
      title={title}
      referralPerson={referralPerson}
    />,
  ];
  return (
    <div>
      {title === 'Choose a location' ? (
        <>
          <div className={`${styles.wrapper} ${isMobile ? styles.mob : ''}`}>
            <div className={`${styles.locationHeader} ${isMobile ? styles.mob : ''}`}>
              {title}
            </div>
            <div className={styles.geoTextfieldDiv}>
              <GeoLocationInput
                // onClickIcon={() => openGeoLocation()}
                placeholder="Enter Postal Code"
                onKeyUp={e => handleGetZip(e.target.value)}
                value={location}
                onChange={e => setlocation(e.target.value)}
                className={`${styles.geoTextfield}${isMobile ? styles.mob : ''}`}
                rightIcon={geoSearch}
              />
              <img
                className={`${styles.geoIcon} ${isMobile ? styles.mob : ''}`}
                src={geoLocation}
                alt=""
                onClick={() => openGeoLocation()}
              ></img>
            </div>
          </div>
        </>
      ) : title == 'Congratulation!' ? null : (
        <div
          className={`${styles.header} ${isMobile ? styles.mob : ''} ${
            title === 'Provide your email address' ? styles.membership : ''
          }`}
        >
          {title}
        </div>
      )}
      <div className={styles.container}>{components[section]}</div>
      <ReferralPopup
        open={isOpenReferralPopup}
        onClose={() => setOpenReferralPopup(false)}
        referralPerson={referralPerson}
      />
    </div>
  );
};

RegistrationNew.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  clubs: PropTypes.array.isRequired,
  newOlsPlans: PropTypes.array.isRequired,
  card: PropTypes.object.isRequired,
  referralPerson: PropTypes.object.isRequired,
  planInfo: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  getNewOlsPlans: PropTypes.func.isRequired,
  addUserDetails: PropTypes.func.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getPlanInfo: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  checkReferralCode: PropTypes.func.isRequired,
  updateUtmInfo: PropTypes.func.isRequired,
};

export default RegistrationNew;
